import React from "react";
import { Modal } from "../../Modal";

export const LevelReminder = ({ openedModal, onClose }) => {
  return (
    <Modal isOpened={openedModal} onClose={onClose}>
      <div className="flex flex-col items-center space-y-12 sm:w-full ">
        <div className="flex flex-col items-center space-y-6">
          <span className="text-[60px] sm:text-[40px]">¯\_(ツ)_/¯</span>
          <span className="text-2xl font-bold text-center">
            Your frens <br /> outstripped you
          </span>
        </div>
        <div className="flex w-full justify-between">
          <span className="">You lost:</span>

          <span>[123.45] BNB</span>
        </div>
        <div className="flex flex-col items-center space-y-6 sm:w-full">
          <span className="font-light text-sm">
            Hit the button to close the gap.
          </span>
          <button
            onClick={onClose}
            className="flex items-center justify-center py-[10px] w-[312px] sm:w-full rounded-xl buttonMainStyle"
          >
            <span className="text-black font-medium">I’m on it</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};
