export const levelDescriptions = [
  "The Virtual Art Heist: Steal a priceless digital artwork from a virtual gallery, replacing it with a copy of the Mona Lisa hitting the green button",

  "The Twitter Tweet Tsunami: Hack Elon Musk’s { account and flood their feed with tweets about your favorite 80`s band.",

  "The Emoji Apocalypse: Change all the emojis on Telegram to gibberish, causing widespread confusion and panic.",

  "The Traffic Light Symphony:  Hack the city`s traffic light system to create a synchronized light show, turning rush hour into a chaotic disco.",

  'The Voice Assistant Takeover:  Take control of all smart home devices in a neighborhood, using them to play a coordinated prank on the residents, like a synchronized "Push the button" chorus.',

  "The Dating App Sabotage: Crack a dating app, leading to a series of awkward and hilarious dates for unsuspecting users.",

  "The Supermarket Scramble:  Hack the inventory system of a supermarket, replacing all the products with bizarre substitutes.",

  "The Meme Machine:  Create a self-replicating AI that generates viral memes based on user inputs, leading to a meme explosion across the internet.",

  "The Unbreakable Code:  Crack a code so complex it was thought to be unbreakable, revealing a secret message from an ancient civilization.",

  "The Sentient Bot Swarm:  Hack a swarm of delivery bots and program them to perform an elaborate synchronized dance routine over a major city.",

  "The Pet-Tracking Conspiracy:  Hack the tracking devices on all pets in a city, leading to a city-wide animal rebellion against their owners.",

  "The Holographic Hoax:  Project realistic holograms of famous figures into public spaces, causing widespread confusion and disbelief.",

  "The Language Translator Glitch:  Introduce a massive glitch into all translation software, leading to a hilarious global communication meltdown.",

  "The Robot Uprising:  Hack into a city`s robot workforce and program them to follow the rules of a bizarre, made-up game, leading to a chaotic robot performance.",

  "The Meme-ocracy: Hack into a political campaign`s website and replace their campaign slogans with hilarious memes.",

  "The Musical Mayhem: Hack into a streaming platform and replace all the music with a single, ear-splitting, 10-hour loop of your favorite (or most disliked) song.",

  "The Internet Blackout:  Temporarily disable internet access in a specific region, replacing it with a gif of a green button.",

  "The Great Coffee Heist: Hack into a coffee shop`s ordering system and ensure everyone gets the wrong order, creating a massive wave of confusion and coffee-fueled frustration.",

  "The Unstoppable Autocorrect:  Take control of a major autocorrect algorithm and make it replace every word with a nonsensical phrase, creating a world of miscommunication and amusement.",

  "The Global Dance Party: Coordinate every public screen in the world to play the same popular song, turning Earth into a planet-wide dance floor for a few minutes.",

  "The Phone Tree:  Create a temporary network where phone calls are routed through random strangers, forcing people to have unexpected conversations.",

  "The Digital Detox:  Turn off all unnecessary internet connectivity for a specific city for one day, prompting people to reconnect with each other offline.",

  "The Cat Video Flood:  Replace every video on a popular online platform with random cat videos for a few hours, creating a hilarious internet meltdown.",
];
