import React, { useEffect, useMemo, useState } from "react";
import { useGetUserInfo } from "../../helpers/hooks/useGetUserInfo";
import { useWeb3React } from "@web3-react/core";
import { ConnectSection } from "../../features/login/ConnectSection";
import { RegisterSection } from "../../features/login/RegisterSection";
import { useCallTransaction } from "../../helpers/hooks/useCallTransaction";
import { useCheckReflink } from "../../helpers/hooks/useCheckReflink";
import { Header } from "../../components/Header";
import { callNotification } from "../../helpers/notification";
import { LoadingSection } from "../../features/login/LoadingSection";

export const Login = () => {
  const [count, setCount] = useState(0);
  const [isMainLoading, setIsMainLoading] = useState(true);
  const { account } = useWeb3React();
  const { getUserInfo, userInfo, isRegisteredUser, isLoadingUserInfo } =
    useGetUserInfo();
  const {
    transactionInfo: transactionInfoRegister,
    onCallTransaction: onCallTransactionRegister,
  } = useCallTransaction();

  useEffect(() => {
    if (account) {
      let interval;
      if (count < 34) {
        interval = setInterval(() => {
          setCount((temp) => temp + 1);
        }, 100);
      }
      return () => clearInterval(interval);
    }
  }, [count, account]);

  console.log(isLoadingUserInfo, count);

  useEffect(() => {
    if (!isLoadingUserInfo && count >= 33) {
      setIsMainLoading(false);
    }
  }, [isLoadingUserInfo, count]);

  const { uplineData, checkReflinkId } = useCheckReflink();

  useEffect(() => {
    checkReflinkId();
  }, []);

  useEffect(() => {
    if (account) {
      getUserInfo(account, false);
    }
  }, [account]);

  useEffect(() => {
    if (transactionInfoRegister?.isSuccess && account) {
      getUserInfo(account, false);
      callNotification({ type: "info", message: "Registration success!" });
    }
  }, [account, transactionInfoRegister]);

  const renderContent = useMemo(() => {
    if (!account) {
      return <ConnectSection />;
    } else {
      if (isMainLoading) {
        return <LoadingSection />;
      } else {
        return (
          <RegisterSection
            onCallTransaction={onCallTransactionRegister}
            transactionInfo={transactionInfoRegister}
            isRegisteredUser={isRegisteredUser}
          />
        );
      }
    }
  }, [isRegisteredUser, account, transactionInfoRegister, isMainLoading]);

  return (
    <div className="min-h-screen w-full bg-[#070707] relative flex flex-col items-center justify-center overflow-hidden ">
      {account && <Header className="top-0 fixed px-10 py-5" />}
      {renderContent}
    </div>
  );
};
