import React, { useEffect } from "react";
import { bigIntToInt } from "../../helpers/numbers";
import { useWeb3React } from "@web3-react/core";
import { useCallTransaction } from "../../helpers/hooks/useCallTransaction";
import { useModal } from "../../helpers/hooks/useModal";
import { ClaimModal } from "../Modals/ClaimModal";
import { useClaimRewards } from "../../helpers/hooks/useClaimRewards";
import { useGetUserInfo } from "../../helpers/hooks/useGetUserInfo";

export const ClaimBlock = () => {
  const { account } = useWeb3React();
  const { openedModal, onClose, onOpen } = useModal();
  const { getUserInfo, userInfo } = useGetUserInfo();

  const { claimRewards, isLoadingClaim, rewards, getRewardsForClaim } =
    useClaimRewards();

  const {
    transactionInfo: transactionInfoClaim,
    onCallTransaction: onCallTransactionClaim,
  } = useCallTransaction();

  useEffect(() => {
    if (account) {
      getRewardsForClaim(account);
    }
  }, [account]);

  const claimClick = async () => {
    if (!!rewards) {
      if (!isLoadingClaim) {
        const result = await claimRewards();
        onCallTransactionClaim(result);
      }
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    if (transactionInfoClaim?.isSuccess && account) {
      updateInfo();
    }
  }, [account, transactionInfoClaim]);

  const updateInfo = () => {
    if (account) {
      getUserInfo(account);
      getRewardsForClaim(account);
    }
  };

  return (
    <div className="flex flex-col space-y-6 p-4 w-full border-[1px] border-green rounded-xl">
      <div className="flex w-full justify-between">
        <span className="text-2xl ">My reward</span>
        <img src="/icons/main/leftBar/infoIcon.svg" />
      </div>
      <div className="flex w-full justify-between">
        <span className="">
          {(bigIntToInt(rewards) / 1e18 || 0).toFixed(4)} BNB
        </span>
        <button
          onClick={() => claimClick()}
          className="flex items-center justify-center buttonSecondStyle rounded-full w-fit px-[10px] h-[28px]"
        >
          <span className="font-medium text-black text-sm">
            {isLoadingClaim || transactionInfoClaim?.isWaiting
              ? "Loading.."
              : "Claim"}
          </span>
        </button>
      </div>
      <ClaimModal openedModal={openedModal} onClose={onClose} />
    </div>
  );
};
