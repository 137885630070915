import React, { useEffect } from "react";
import { useModal } from "../../../helpers/hooks/useModal";
import { useWeb3React } from "@web3-react/core";
import { useCallTransaction } from "../../../helpers/hooks/useCallTransaction";
import { bigIntToInt } from "../../../helpers/numbers";
import { InviteModal } from "../../../components/Modals/InviteModal";
import { ClaimBlock } from "../../../components/ClaimBlock";
import { Timer } from "../../../components/Timer";

export const LeftBar = ({
  currentLevel,
  isCompleted,
  setIsCompleted,
  timerStep,
  timerValue,
  tokens = 0,
  id = null,
}) => {
  const {
    openedModal: openedModalInvite,
    onClose: onCloseInvite,
    onOpen: onOpenInvite,
  } = useModal();

  return (
    <div className="flex flex-col space-y-12 max-w-[260px] w-full sm:hidden">
      <div className="flex flex-col space-y-4">
        <div className="flex w-full justify-between">
          <span>tokens</span>
          <div className="flex space-x-3">
            <span>{tokens}</span>
            <img src="/icons/main/leftBar/tokenIcon.svg" />
          </div>
        </div>
        <div className="flex justify-between w-full">
          <span className="text-sm leading-[22.4px]">
            Invite frens
            <br /> to get 1000 BTN
          </span>
          <button
            onClick={onOpenInvite}
            className="flex items-center justify-center buttonSecondStyle rounded-xl h-[38px] w-[76px]"
          >
            <span className="text-black text-sm font-semibold">Invite</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col space-y-6">
        <ClaimBlock />
        <Timer
          currentLevel={currentLevel}
          timerStep={timerStep}
          isCompleted={isCompleted}
          setIsCompleted={setIsCompleted}
          time={timerValue}
        />
      </div>
      <InviteModal
        userId={bigIntToInt(id)}
        openedModal={openedModalInvite}
        onClose={onCloseInvite}
      />
    </div>
  );
};
