import React from "react";

export const Loading = () => {
  return (
    <div className="min-h-screen w-full bg-[#070707] relative flex flex-col space-y-[20px] items-center justify-center overflow-hidden">
      <span className="text-[32px] uppercase font-bold">Button CA$H</span>
      <div className="flex flex-col items-center space-y-3">
        <div className="flex items-center space-x-[2px] w-[276px] h-[46px] border-[2px] border-green relative">
          <span className="text-xl font-bold absolute right-3 top-1/2 -translate-y-1/2">
            55%
          </span>
          <div className="w-[6px] bg-green h-[38px]" />
          <div className="w-[6px] bg-green h-[38px]" />
          <div className="w-[6px] bg-green h-[38px]" />
        </div>
        <span className="font-light">
          You’ve found a backdoor!
          <br /> Hacking starts in 1…2…3…
        </span>
      </div>
    </div>
  );
};
