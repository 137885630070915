import React from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import clsx from "clsx";

export const Modal = ({
  isOpened,
  onClose,
  children,
  className,
  isDisableOnClose,
  withoutClose,
}) => {
  const preventDefaultCallBack = (callback) => (e) => {
    e.preventDefault();

    callback && callback(e);
  };

  return (
    <DialogOverlay
      className="header-dialog-overlay  sm:flex sm:flex-col sm:!justify-end  "
      isOpen={isOpened}
      as="div"
      onClick={!isDisableOnClose && preventDefaultCallBack(onClose)}
    >
      <DialogContent
        className={clsx(
          className,
          "flex self-center rounded-[20px] py-12 sm:w-[110%] px-12 bg-black border-l-[1px] border-t-[1px] border-r-[1px] border-b-[1px] sm:border-l-[0px] sm:border-b-[0px] sm:border-r-[0px] border-green  justify-center text-green sm:border-t-[1px] sm:border-t-green sm:m-0 sm:w-screen sm:max-w-full relative sm:self-end sm:-mb-5 sm:px-4 outline-none   ",
        )}
        as="div"
        aria-label="modal"
      >
        {children}
        <div
          className={`flex right-0 top-3 cursor-pointer w-[30px] h-[30px] absolute `}
          onClick={preventDefaultCallBack(onClose)}
          onTouchEnd={preventDefaultCallBack(onClose)}
        >
          {/* <img className="h-[30px] w-[30px]" src="/icons/modal/closeIcon.svg" /> */}
          <span className="text-lg modalCloseIconShadow pt-3 ">x</span>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};
