import { useState } from "react";
import { useWeb3React } from "@web3-react/core";

export const useBalance = (props) => {
  const [balance, setBalance] = useState({});
  const { withoutFixed = false, contractsFetch } = props ?? {};

  const { account, provider } = useWeb3React();

  const fetchBalance = async () => {
    try {
      const resultBnb = await provider.getBalance(account);
      const balanceBnb = parseInt(resultBnb) / 1e18;

      setBalance({
        balanceBnb,
        isFirstLoaded: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return {
    balanceBnb: withoutFixed
      ? balance.balanceBnb ?? 0
      : parseFloat(balance.balanceBnb ?? 0).toFixed(3),
    isFirstLoaded: balance.isFirstLoaded,
    fetchBalance,
  };
};
