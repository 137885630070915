import React from "react";
import { Modal } from "../../Modal";
import { copy } from "../../../helpers/copy";

export const InviteModal = ({ userId = null, openedModal, onClose }) => {
  const refLink = window.location.host + "/?inviteId=" + userId;

  return (
    <Modal isOpened={openedModal} onClose={onClose}>
      <div className="flex flex-col items-center space-y-12 sm:w-full">
        <div className="flex flex-col items-center space-y-6">
          <img
            className="w-[100px] h-[100px]"
            src="/icons/modals/inviteModal/donutIcon.svg"
          />
          <div className="flex flex-col items-center justify-center space-y-3">
            <span className="text-2xl font-bold">Invite frens</span>
            <span className="text-center sm:text-sm">
              Get 1000 $BTN for each fren who <br /> activated the block
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-3 w-full">
          <span className="text-lg">Drop this link</span>
          <div className="flex items-center justify-between w-full px-4 py-[10px] border-green border-[1px] rounded-xl w-[312px] hover:bg-extraLightGreen sm:w-full">
            <span className="text-sm">{refLink}</span>
            <button
              className="copyIcon z-[111] w-6 h-6"
              onClick={() => copy(refLink)}
            ></button>
          </div>
        </div>
        <a
          target="_blank"
          href={`https://telegram.me/share/url?url=${refLink}`}
          className="flex items-center justify-center buttonMainStyle rounded-xl w-full py-[10px]"
          rel="noreferrer"
        >
          <span className="text-black text-lg font-medium">Invite frens</span>
        </a>
      </div>
    </Modal>
  );
};
