import React from "react";
import { toast } from "react-toastify";
import clsx from "clsx";

const defaultStyles =
  "!bg-main-blue !rounded !p-4 !text-black !text-medium !text-sm !leading-5 sm:!p-4 sm:!mx-5 sm:!my-2.5";

const stylesByType = {
  [toast.TYPE.SUCCESS]: "!bg-green !rounded-[12px] !px-[4px] !py-[10px]",
  [toast.TYPE.ERROR]:
    "!bg-black !border-green !border !text-green !rounded-[12px] !px-[4px] !py-[10px]",
  [toast.TYPE.INFO]: "!bg-green !rounded-[12px] !px-[4px] !py-[10px]",
  [toast.TYPE.WARNING]:
    "!bg-black !border-green !border !text-green !rounded-[12px] !px-[4px] !py-[10px]",
  [toast.TYPE.DEFAULT]:
    "!bg-black !border !border-green !text-green !rounded-[12px] !px-[4px] !py-[10px]",
};

const iconsByType = {
  [toast.TYPE.SUCCESS]: (
    <img
      src="/icons/notifications/success.svg"
      className={"w-6 h-6 flex-shrink-0"}
      alt=""
    />
  ),
  [toast.TYPE.ERROR]: (
    <img
      src="/icons/notifications/warning.svg"
      className={"w-6 h-6 flex-shrink-0"}
      alt=""
    />
  ),
  [toast.TYPE.INFO]: (
    <img
      src="/icons/notifications/info.svg"
      className={"w-6 h-6 flex-shrink-0"}
      alt=""
    />
  ),
  [toast.TYPE.WARNING]: (
    <img
      src="/icons/notifications/warning.svg"
      className={"w-6 h-6 flex-shrink-0"}
      alt=""
    />
  ),
  [toast.TYPE.DEFAULT]: (
    <img
      src="/icons/notifications/token.svg"
      className={"w-6 h-6 flex-shrink-0"}
      alt=""
    />
  ),
};

export const callNotification = ({
  type = "success",
  message = "",
  ...props
}) => {
  const isServer = typeof window === "undefined";

  if (isServer) return;

  return toast[type](message, {
    position: window.innerWidth <= 767 ? "top-center" : "bottom-left",
    className: clsx(defaultStyles, stylesByType[type]),
    progressClassName: "hidden",
    icon: iconsByType[type],
    autoClose: 3000,
    hideProgressBar: false,
    closeButton: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    ...props,
  });
};
