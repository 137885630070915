import copyToClipBoard from "copy-to-clipboard";
import { callNotification } from "./notification";

export const copy = (value) => {
  const isCopied = copyToClipBoard(value);

  if (isCopied) {
    callNotification({ type: "info", message: "Copied!" });
  } else {
  }
};
