import React, { useEffect, useState } from "react";
import { useRegistation } from "../../../helpers/hooks/useRegistration";
import { Input } from "../../../components/Input";
import { useCheckReflink } from "../../../helpers/hooks/useCheckReflink";
import Spline from "@splinetool/react-spline";
import { Link } from "react-router-dom";

export const RegisterSection = ({
  onCallTransaction,
  transactionInfo,
  isRegisteredUser,
}) => {
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const { uplineData, checkReflinkId } = useCheckReflink();
  const { registration } = useRegistation();
  const [upline, setUpline] = useState({ value: 1 });

  useEffect(() => {
    checkReflinkId();
  }, []);

  useEffect(() => {
    if (uplineData) {
      setUpline(uplineData);
    }
  }, [uplineData]);

  const clickRegister = async () => {
    setIsLoadingRegister(true);
    try {
      const result = await registration(upline?.value);

      onCallTransaction(result);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (transactionInfo?.isSuccess || transactionInfo?.isError) {
      setIsLoadingRegister(false);
    }
  }, [transactionInfo]);

  const buttonStyle =
    "flex items-center justify-center buttonMainStyle h-[48px] w-[350px] rounded-xl sm:w-fit sm:px-4 sm:h-[40px] sm:w-full text-black";

  if (isRegisteredUser) {
    return (
      <div className="flex flex-col items-center justify-center text-center bg-black w-screen h-screen space-y-6 sm:px-4 sm:space-y-6">
        <img src="/icons/registerSection/donut.svg" />
        <div className="flex flex-col items-center justify-center mb-5 space-y-3">
          <span className="text-[32px] uppercase font-semibold">
            Hey, you nailed it
          </span>
          <div className="max-w-[350px] w-full font-light">
            That`s awesome, hacker. Let`s crush those buttons and get rich
          </div>
        </div>
        <Link to={"/dashboard" + location.search} className={buttonStyle}>
          <span className="text-black text-lg font-medium sm:text-sm">
            Go Hack
          </span>
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center text-center bg-black w-screen h-screen space-y-6 sm:px-4 sm:space-y-6">
      <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-6">
        <Spline
          className="flex items-center justify-center scale-90 sm:scale-75"
          scene={"https://prod.spline.design/d34hSLhPqemUdYju/scene.splinecode"}
        />
        <div className="flex flex-col space-y-3 sm:space-y-3">
          <span className="text-[32px] uppercase font-bold leadinng-normal">
            Become a hacker
          </span>
          <span className="max-w-[460px] w-full font-light leading-normal">
            Join for free and start making bank
          </span>
        </div>
      </div>
      <div className="flex flex-col space-y-3 sm:w-full">
        <div className="flex w-full items-center justify-between border-[1px] border-green h-[48px] rounded-xl px-5 w-[350px] sm:w-full">
          <span className="text-sm whitespace-nowrap">your inviter ID</span>
          <Input
            placeholder="Your uplineId"
            className="!max-w-[170px]"
            value={upline?.value}
            onChange={(e) => setUpline({ ...upline, value: e.target.value })}
          />
        </div>
        <button
          disabled={isLoadingRegister}
          onClick={() => clickRegister()}
          className="flex items-center justify-center buttonMainStyle h-[48px] w-[350px] sm:w-full rounded-xl sm:w-fit sm:px-4"
        >
          <span className="text-black text-lg font-medium sm:text-sm">
            {transactionInfo?.isWaiting ? (
              <span className="loading">Loading...</span>
            ) : (
              "Let`s do it"
            )}
          </span>
        </button>
      </div>
    </div>
  );
};
