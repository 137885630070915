import React, { useEffect, useRef } from "react";

import { useRequest } from "../../../helpers/hooks/useRequest";
import { ActivitiesRepository } from "../../../connectors/repositories/activities";

const shortenAddress = (address) => {
  if (address.length <= 12) {
    return address;
  }
  return address.slice(0, 9) + "..." + address.slice(-4);
};

export const Transactions = () => {
  const requestMapper = (prev, data) => {
    if (prev?.length) {
      return [...data, ...prev];
    }

    return data;
  };

  const { data, call } = useRequest(
    ActivitiesRepository.getTransactions,
    [],
    requestMapper,
  );
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    call();

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        call();
      }
    };

    scrollContainerRef.current.addEventListener("scroll", handleScroll);

    const intervalId = setInterval(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({ top: 30, behavior: "smooth" });
      }
    }, 1000);

    return () => {
      clearInterval(intervalId); // Очищаем интервал при размонтировании компонента
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div
      ref={scrollContainerRef}
      className="fade-text flex flex-col space-y-[9px] w-full max-w-[181.69px] absolute right-[108px] bottom-[50px] h-[155px] overflow-auto z-[11] sm:space-y-[5.8px] sm:h-[71px] sm:max-w-[150px] sm:left-4 sm:bottom-auto sm:top-[125px]"
    >
      {data?.map((transaction, index) => (
        <div key={index} className="flex w-full text-[14.6px] sm:text-[8px]   ">
          <span className="min-w-[80px] sm:min-w-[50px]">
            ID {transaction.userId}
          </span>
          <div className="bg-green h-[20px] w-[2px] right-0 relative sm:h-[11px]"></div>
          <span className="min-w-[100px] text-end sm:min-w-[60px]">
            {transaction.reward.toFixed(3)} BNB
          </span>
        </div>
      ))}
    </div>
  );
};
