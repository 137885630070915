import React, { useEffect } from "react";
import { EarnFrensLayout } from "../../layouts/EarnFrensLayout";
import { FrensList } from "../../features/frens/FrensList";
import { TokensCount } from "../../features/frens/TokensCount";
import { useWeb3React } from "@web3-react/core";
import { useGetUserInfo } from "../../helpers/hooks/useGetUserInfo";
import { useModal } from "../../helpers/hooks/useModal";
import { InviteModal } from "../../components/Modals/InviteModal";
import { bigIntToInt } from "../../helpers/numbers";
import { shortenAddress } from "../../helpers/format";
import { useNavigate } from "react-router-dom";

export const Frens = () => {
  const navigate = useNavigate();
  const { account } = useWeb3React();

  const { getUserInfo, userInfo } = useGetUserInfo();

  const {
    openedModal: openedModalInvite,
    onClose: onCloseInvite,
    onOpen: onOpenInvite,
  } = useModal();

  useEffect(() => {
    if (account) {
      getUserInfo(account, true);
    } else {
      navigate("/");
    }
  }, [account]);

  return (
    <EarnFrensLayout>
      <div className="flex flex-col items-center justify-center relative space-y-6 w-full max-w-[470px] sm:justify-start sm:max-w-full sm:px-4 pt-[18px] sm:pt-[0] sm:pb-[115px]">
        <img
          className="sm:w-[60px] sm:h-[60px] iconsShadow"
          src="/icons/frens/frensIcon.svg"
        />
        <span className="text-2xl leading-normal sm:pt-0 font-bold">Frens</span>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            <img src="/icons/frens/inviterIcon.svg" />
            <span>
              ID {userInfo?.referrerId} [{shortenAddress(userInfo?.referrer, 2)}
              ]
            </span>
          </div>
          <span>Your Inviter</span>
        </div>
        <span className="font-light text-center sm:text-xs">
          <span className="sm:text-xs">
            +1000 $BTN for each fren who activated the block
          </span>
          <br /> +100 BTN every time they activate block
        </span>
        <button
          onClick={() => onOpenInvite()}
          className="flex items-center justify-center buttonMainStyle rounded-xl bg-green h-[48px] w-full"
        >
          <span className="text-black font-medium">Invite new hackers</span>
        </button>
        <div className="flex flex-col items-start w-full space-y-6">
          <span className="text-lg font-bold ">
            My frens — {userInfo?.partnersTotal?.count}
          </span>
        </div>
        <FrensList totalPartners={userInfo?.partnersTotal?.count} />
        <InviteModal
          userId={bigIntToInt(userInfo?.id)}
          openedModal={openedModalInvite}
          onClose={onCloseInvite}
        />
      </div>
    </EarnFrensLayout>
  );
};
