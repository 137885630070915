import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const Menu = ({ className }) => {
  const [currentTub, setCurrentTab] = useState(0);
  const location = useLocation();

  const menuItem = [
    {
      title: "Button",
      icon: "buttonIcon",
      href: "/dashboard",
    },
    {
      title: "Frens",
      icon: "frensIcon",
      href: "/frens",
    },
    {
      title: "Earn",
      icon: "earnIcon",
      href: "/earn",
    },
    {
      title: "$BTN",
      icon: "btnIcon",
      href: "/btn",
    },
  ];
  return (
    <div
      className={`fixed bottom-[40px] left-[95px] items-center px-4 z-[12] sm:w-full sm:left-auto sm:bottom-[15px] ${className}`}
    >
      <div className="flex flex-col rounded-xl bg-black border-green border-[1px] space-y-[1px] h-full w-[68px] p-[6px] sm:flex-row sm:h-[68px] sm:w-full sm:rounded-[18px] sm:space-y-0  ">
        {menuItem.map((item, itemIndex) => {
          const isActive = location.pathname === item?.href;
          return (
            <Link
              to={item?.href}
              onClick={() => {
                setCurrentTab(itemIndex);
              }}
              className={`flex flex-col py-[6px] px-[8px] items-center justify-between rounded-md sm:rounded-xl hover:bg-extraLightGreen ${isActive ? "bg-lightGreen" : ""} h-full w-full`}
              key={itemIndex}
            >
              <img src={`/icons/main/menu/${item.icon}.svg`} />
              <span className="text-[10px]">{item.title}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
