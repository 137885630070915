import React, { useMemo, useState } from "react";
import { Modal } from "../../Modal";
import { Item } from "../../../features/main/LevelsMap/item";
import { levelDescriptions } from "../../../helpers/levelsDesc";

export const LevelInfoModal = ({ level, openedModal, onClose, active }) => {
  const renderContent = useMemo(() => {
    if (active) {
      return (
        <>
          <div className="flex flex-col items-center space-y-6">
            <Item disableInfoBtns active index={level?.num} {...level} />
            <span className="text-2xl font-bold text-center">
              Block {level?.num} hacked <br />
              Nice one!
            </span>
          </div>
          <div className="flex flex-col space-y-6 w-[312px]">
            <div className="flex flex-col space-y-3">
              <div className="flex w-full justify-between">
                <span>Block profit:</span>
                <span>[{level?.info?.totalProfit}] BNB</span>
              </div>
              <div className="flex w-full justify-between">
                <span>Frens joined:</span>
                <span>[{level?.info?.partnersCount}]</span>
              </div>
            </div>
            <div className="flex w-full justify-between">
              <span>Lost profit:</span>
              {!!level?.info?.missedReward && (
                <img src="/icons/main/levelsMap/reminder.svg" />
              )}
              <span>[{level?.info?.missedReward / 1e18 || 0}] BNB</span>
            </div>
          </div>
          <div className="flex flex-col space-y-3 w-full items-center">
            {/* <a className="underline cursor-pointer hover:opacity-80">
              View transaction
            </a> */}
            <button
              onClick={onClose}
              className="flex items-center justify-center py-[10px] w-full rounded-xl buttonMainStyle"
            >
              <span className="text-black font-medium">Hack more</span>
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Item disableInfoBtns active index={level?.num} {...level} />
          <span className="text-center font-light max-w-[312px] sm:max-w-full">
            {!!levelDescriptions[level?.num - 1]
              ? levelDescriptions[level?.num - 1]
              : levelDescriptions[0]}
          </span>
          <button
            onClick={() => onClose()}
            className="flex items-center justify-center py-[10px] w-full rounded-xl buttonMainStyle"
          >
            <span className="text-black font-medium">go to button</span>
          </button>
        </>
      );
    }
  }, [active, level]);
  return (
    <Modal isOpened={openedModal} onClose={onClose}>
      <div className="flex flex-col items-center space-y-12 sm:w-full ">
        {renderContent}
      </div>
    </Modal>
  );
};
