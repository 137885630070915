import React, { useEffect, useState } from "react";

export const LoadingSection = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let interval;
    if (count < 34) {
      interval = setInterval(() => {
        setCount((temp) => temp + 1);
      }, 100);
    }
    return () => clearInterval(interval);
  }, [count]);

  const percent = ((count / 34) * 100).toFixed(0);

  return (
    <div className="min-h-screen w-full bg-[#070707] relative flex flex-col space-y-[20px] items-center justify-center overflow-hidden">
      <span className="text-[32px] uppercase font-bold">Button CA$H</span>
      <div className="flex flex-col items-center space-y-3">
        <div className="relative flex items-center space-x-[2px] w-[276px] h-[46px] border-[2px] border-green relative sm:scale-75">
          <span className="absolute top-0 right-[-60px] text-xl font-bold absolute right-3 top-1/2 -translate-y-1/2">
            {percent}%
          </span>
          {Array.from(new Array(count)).map((item, itemIndex) => {
            return (
              <div className="w-[6px] bg-green h-[38px]" key={itemIndex} />
            );
          })}
        </div>
        <span className="font-light">
          You’ve found a backdoor!
          <br /> Hacking starts in {percent > 25 && <span>1…</span>}
          {percent > 50 && <span>2…</span>}
          {percent > 75 && <span>3…</span>}
        </span>
      </div>
    </div>
  );
};
