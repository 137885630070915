import React, { useMemo, useState } from "react";
import { Modal } from "../../Modal";
import { InviteModal } from "../InviteModal";
import { useModal } from "../../../helpers/hooks/useModal";
import { Link } from "react-router-dom";

export const ClaimModal = ({ openedModal, onClose }) => {
  const {
    openedModal: openedInviteModal,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = useModal();
  const isBnb = false;

  const renderContent = useMemo(() => {
    if (isBnb) {
      return (
        <div className="flex flex-col items-center space-y-12 sm:w-full ">
          <div className="flex flex-col items-center space-y-6">
            <img src="/icons/modals/claimModal/starIcon.svg" />
            <div className="flex flex-col items-center space-y-3">
              <span className="text-2xl font-bold">Sorry, bro</span>
              <div className="font-light max-w-[324px] text-center sm:max-w-[370px]">
                You’re short on BTN
              </div>
            </div>
          </div>
          <button
            onClick={() => onOpenInviteModal()}
            className="flex items-center justify-center py-[10px] w-full rounded-xl buttonMainStyle"
          >
            <span className="text-black font-medium w-[265px] sm:w-full">
              Invite frens
            </span>
          </button>
          <InviteModal
            openedModal={openedInviteModal}
            onClose={onCloseInviteModal}
          />
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-center space-y-12 sm:w-full ">
          <div className="flex flex-col items-center space-y-6">
            <img src="/icons/modals/claimModal/bnbIcon.svg" />
            <div className="flex flex-col items-center space-y-3">
              <span className="text-2xl font-bold">Sorry, bro</span>
              <div className="font-light max-w-[324px] text-center sm:max-w-[370px]">
                You’re short on BNB :( <br />
                Invite new frens to get some
              </div>
            </div>
          </div>
          <Link
            to={"/frens"}
            className="flex items-center justify-center py-[10px] w-full rounded-xl buttonMainStyle w-[265px] sm:w-full"
          >
            <span className="text-black font-medium">Call frens over</span>
          </Link>
        </div>
      );
    }
  }, [isBnb]);

  return (
    <Modal isOpened={openedModal} onClose={onClose}>
      {renderContent}
    </Modal>
  );
};
