import { useState } from "react";
import { useGetContract } from "./useGetContract";
import { CONTRACT_NAMES } from "../constants";
import { LEVEL_PRICE } from "../constants";
import { bigIntToInt, fromWei, toWei } from "../numbers";
import { increaseByPercent } from "../numbers";
import { DEFAULT_GAS_LIMIT } from "../constants";
import { parseErrorToUserReadableMessage } from "../format";
import { callNotification } from "../notification";
import { ActivitiesRepository } from "../../connectors/repositories/activities";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "@ethersproject/bignumber";

export const useBuyLevel = () => {
  const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false);
  const { getContract } = useGetContract();
  const { provider } = useWeb3React();

  const upgradeLvl = async (level) => {
    if (!isLoadingUpgrade) {
      setIsLoadingUpgrade(true);
      try {
        const signature = await ActivitiesRepository.getSignature(level);

        if (signature?.sig) {
          try {
            const contractButton = await getContract(CONTRACT_NAMES.BUTTON);

            let gas = null;

            try {
              gas = await contractButton.estimateGas.buyNewButton(
                level,
                signature?.sig,
                {
                  value: toWei(LEVEL_PRICE[level - 1]),
                },
              );
            } catch (e) {
              callNotification({
                type: "error",
                message: parseErrorToUserReadableMessage(e),
              });
            }

            const result = await contractButton.buyNewButton(
              level,
              signature?.sig,
              {
                value: toWei(LEVEL_PRICE[level - 1]),
                gasPrice: BigNumber.from(3000000000),
                gasLimit: parseInt(gas)
                  ? increaseByPercent(gas)
                  : DEFAULT_GAS_LIMIT,
              },
            );

            return result;
          } catch (e) {
            callNotification({
              type: "error",
              message: parseErrorToUserReadableMessage(e),
            });
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingUpgrade(false);
      }
    }
  };

  return {
    upgradeLvl,
    isLoadingUpgrade,
  };
};
