import React, { useEffect } from "react";
import { bigIntToInt } from "../../../helpers/numbers";
import { useWeb3React } from "@web3-react/core";
import { useClaimRewards } from "../../../helpers/hooks/useClaimRewards";
import { useGetUserInfo } from "../../../helpers/hooks/useGetUserInfo";
import { useModal } from "../../../helpers/hooks/useModal";
import { ClaimModal } from "../../../components/Modals/ClaimModal";
import { useCallTransaction } from "../../../helpers/hooks/useCallTransaction";

export const UserStatics = () => {
  const { account } = useWeb3React();
  const { openedModal, onClose, onOpen } = useModal();
  const { getUserInfo, userInfo } = useGetUserInfo();

  const { claimRewards, isLoadingClaim, rewards, getRewardsForClaim } =
    useClaimRewards();

  const {
    transactionInfo: transactionInfoClaim,
    onCallTransaction: onCallTransactionClaim,
  } = useCallTransaction();

  useEffect(() => {
    if (account) {
      getRewardsForClaim(account);
    }
  }, [account]);

  const claimClick = async () => {
    if (!!rewards) {
      if (!isLoadingClaim) {
        const result = await claimRewards();
        onCallTransactionClaim(result);
      }
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    if (transactionInfoClaim?.isSuccess && account) {
      updateInfo();
    }
  }, [account, transactionInfoClaim]);

  const updateInfo = () => {
    if (account) {
      getUserInfo(account, true);
      getRewardsForClaim(account);
    }
  };

  useEffect(() => {
    if (account) {
      getUserInfo(account, true);
    }
  }, [account]);

  return (
    <div className="flex flex-col items-center border-[1px] border-green rounded-xl w-full ">
      <div className="flex flex-col w-full p-4 space-y-4">
        <div className="flex justify-between w-full">
          <span className="font-bold sm:text-xs sm:font-medium">
            Total loot
          </span>
          <span className="text-sm">
            {bigIntToInt(userInfo?.totalProfit) / 1e18 || 0} BNB
          </span>
        </div>
        <div className="w-full bg-green opacity-10 h-[1px] " />
        <div className="flex justify-between w-full">
          <span className="font-bold sm:text-xs sm:font-medium">
            Total reward for frens
          </span>
          <span className="text-sm">
            {bigIntToInt(userInfo?.partnersTotal?.profit) / 1e18 || 0} BNB
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between w-full bg-lightGreen p-4">
        <span className="font-bold sm:text-xs sm:font-medium">
          You can claim now
        </span>
        <div className="flex space-x-3 items-center">
          <span className="text-sm">
            {bigIntToInt(rewards) / 1e18 || 0} BNB
          </span>
          <button
            onClick={() => claimClick()}
            className="flex items-center justify-center buttonSecondStyle rounded-full w-fit px-[10px] h-[28px]"
          >
            <span className="font-medium text-black text-sm">
              {isLoadingClaim || transactionInfoClaim?.isWaiting
                ? "Loading.."
                : "Claim"}
            </span>
          </button>
          <ClaimModal openedModal={openedModal} onClose={onClose} />
        </div>
      </div>
    </div>
  );
};
