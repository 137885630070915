import React, { useEffect } from "react";
import { Modal } from "../../Modal";
import { WALLETS } from "../../../connectors/wallets";
import { useTryActivation } from "../../../helpers/hooks/useTryActivation";
import { useWeb3React } from "@web3-react/core";

export const ConnectWalletModal = ({ openedModal, onClose }) => {
  const { tryActivation } = useTryActivation();
  const { account } = useWeb3React();

  const onWalletClick = (wallet) => () => {
    localStorage.setItem("isUserHandleDisconnect", "");
    tryActivation(wallet.connector);
  };

  useEffect(() => {
    if (account) {
      onClose();
    }
  }, [account]);

  return (
    <Modal isOpened={openedModal} onClose={onClose}>
      <div className="flex flex-col space-y-12">
        <div className="flex flex-col items-center space-y-6">
          <span className="text-2xl font-bold">Which wallet to connect?</span>
          <span className="text-sm font-light text-center max-w-[363px] sm:max-w-full">
            By connecting your wallet, you agree to our Terms and Privacy
            policy, as well as to the fact that you’re awesome
          </span>
        </div>
        <div className="flex flex-col space-y-6">
          {WALLETS.map((item, itemIndex) => {
            return (
              <button
                onClick={onWalletClick(item)}
                className="flex items-center space-x-5 w-full border-r-[1px] border-green"
                key={itemIndex}
              >
                <img className="w-12 h-12" src={item?.icon} />
                <span className="font-medium text-lg">{item?.title}</span>
              </button>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
