import React, { useEffect, useRef, useState } from "react";
import { LeftBar } from "../../features/main/LeftBar";
import { RightBar } from "../../features/main/RightBar";
import { Middle } from "../../features/main/Middle";
import { Button } from "../../features/main/Button";
import { Transactions } from "../../features/main/Transactions";
import { LevelsMap } from "../../features/main/LevelsMap";
import { Header } from "../../components/Header";
import { Menu } from "../../components/Menu";
import { useGetUserInfo } from "../../helpers/hooks/useGetUserInfo";
import { useWeb3React } from "@web3-react/core";
import { useCallTransaction } from "../../helpers/hooks/useCallTransaction";
import { Timer } from "../../components/Timer";
import { activationTimers } from "../../helpers/activationTime";
import { isSameOrBefore } from "../../helpers/date";
import { useGetLevelInfo } from "../../helpers/hooks/useGetLevels";
import { useNavigate } from "react-router-dom";
import config from "../../helpers/config";
import { LevelsMapNew } from "../../features/main/LevelsMapNew";
import { NEED_LIST } from "../../helpers/constants";

export const Main = () => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const { account } = useWeb3React();
  const [isCompleted, setIsCompleted] = useState(false);
  const [timerLevelStep, setTimerLevelStep] = useState(0);
  const [timerStep, setTimerStep] = useState(0);
  const [isAllowToActivate, setIsAllowToActivate] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [currentTimer, setCurrentTimer] = useState(null);
  const { getUserInfo, userInfo } = useGetUserInfo();
  const {
    transactionInfo: transactionInfoUpgrade,
    onCallTransaction: onCallTransactionUpgrade,
    setTransactionInfo: setTransactionUpgrade,
  } = useCallTransaction();

  const getActiveSlideIndex = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setCurrentLevel(swiperRef.current.swiper.activeIndex + 1);
    }
  };

  useEffect(() => {
    if (account) {
      getUserInfo(account, true);
    } else {
      navigate("/");
    }
  }, [account]);

  useEffect(() => {
    if (account && transactionInfoUpgrade?.isSuccess) {
      getUserInfo(account, true);
    }
  }, [account, transactionInfoUpgrade]);

  useEffect(() => {
    if (transactionInfoUpgrade?.isError || transactionInfoUpgrade?.isSuccess) {
      setTransactionUpgrade({
        hash: null,
        isWaiting: false,
        isError: false,
        isSuccess: false,
      });
    }
  }, [transactionInfoUpgrade]);

  const lastNextLevel = activationTimers?.reduce(
    (total, current, index, arr) => {
      if (
        isSameOrBefore(activationTimers[index]?.startTime2) &&
        !isSameOrBefore(activationTimers[index]?.endTime)
      ) {
        return Number(index);
      }

      return Number(total);
    },
    -1,
  );

  const lastActiveLevel = activationTimers?.reduce(
    (total, current, index, arr) => {
      if (isSameOrBefore(activationTimers[index]?.endTime)) {
        return Number(index) + 1;
      }

      return Number(total);
    },
    -1,
  );

  const isNeedToDicrease = (time) => {
    if (!NEED_LIST.includes(account)) {
      return time + 2;
    }
    return time;
  };

  const getTimeForTimer = () => {
    setIsCompleted(false);

    const levelIndex = lastNextLevel;
    const isBeforeTime = !isSameOrBefore(
      activationTimers[levelIndex]?.startTime,
    );
    const isSetFirstTimer = !isSameOrBefore(
      activationTimers[levelIndex]?.startTime2,
    );
    const isSetEndTimer = !isSameOrBefore(
      isNeedToDicrease(activationTimers[levelIndex]?.endTime),
    );

    const isAllowTimers =
      !!activationTimers[levelIndex]?.startTime &&
      !!activationTimers[levelIndex]?.startTime2 &&
      !!activationTimers[levelIndex]?.endTime;

    if (isAllowTimers) {
      if (isBeforeTime) {
        setTimerStep(1);
        setCurrentTimer(activationTimers[levelIndex].startTime);
      } else if (!isBeforeTime && isSetFirstTimer) {
        setTimerStep(2);
        setCurrentTimer(activationTimers[levelIndex].startTime2);
      } else if (!isSetFirstTimer && isSetEndTimer) {
        setTimerStep(3);
        setCurrentTimer(isNeedToDicrease(activationTimers[levelIndex].endTime));
      } else {
        setTimerStep(4);
        setCurrentTimer(null);
      }
    } else {
      setTimerStep(0);
      setCurrentTimer(null);
    }
  };

  const getStatusLevel = () => {
    const levelIndex = currentLevel - 1;
    const isBeforeTime = !isSameOrBefore(
      activationTimers[levelIndex]?.startTime,
    );
    const isSetFirstTimer = !isSameOrBefore(
      activationTimers[levelIndex]?.startTime2,
    );
    const isSetEndTimer = !isSameOrBefore(
      isNeedToDicrease(activationTimers[levelIndex]?.endTime),
    );

    const isAllowTimers =
      !!activationTimers[levelIndex]?.startTime &&
      !!activationTimers[levelIndex]?.startTime2 &&
      !!activationTimers[levelIndex]?.endTime;

    if (isAllowTimers) {
      setIsAllowToActivate(false);
      if (isBeforeTime) {
        setTimerLevelStep(1);
      } else if (!isBeforeTime && isSetFirstTimer) {
        setTimerLevelStep(2);
      } else if (!isSetFirstTimer && isSetEndTimer) {
        setTimerLevelStep(3);
      } else {
        setTimerLevelStep(4);
        setIsAllowToActivate(true);
      }
    } else {
      setTimerLevelStep(0);
      setIsAllowToActivate(false);
    }
  };

  useEffect(() => {
    getTimeForTimer();
    getStatusLevel();
  }, [currentLevel, isCompleted]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getTimeForTimer();
      getStatusLevel();
    }, 300000);
    return () => clearTimeout(timer);
  }, []);

  const { getLevelInfo, levelInfo } = useGetLevelInfo();

  useEffect(() => {
    if (account) {
      getLevelInfo(account, currentLevel);
    }
  }, [account]);

  console.log(currentLevel);

  return (
    <div className="h-full w-full bg-black relative flex flex-col items-center overflow-hidden ">
      <div className="flex flex-col items-center justify-center space-y-6 h-screen w-full px-[108px] pt-5 max-w-[1500px] text-green relative min-h-[750px] sm:px-0 sm:space-y-0 sm:min-h-[780px] lg:px-4 ">
        <Header />
        <div className="flex justify-between w-full h-full sm:px-4 sm:flex-col sm:justify-start sm:space-y-3">
          <LeftBar
            currentLevel={lastNextLevel}
            timerStep={timerStep}
            isCompleted={isCompleted}
            setIsCompleted={setIsCompleted}
            timerValue={currentTimer}
            {...userInfo}
            getUserInfo={getUserInfo}
          />
          <Middle {...userInfo} />
          <RightBar {...userInfo} />
        </div>
        <div className="flex flex-col items-center">
          <LevelsMap
            lastActiveLevel={lastActiveLevel - 1}
            levelInfo={levelInfo}
            timerStep={timerLevelStep}
            swiperRef={swiperRef}
            getActiveSlideIndex={getActiveSlideIndex}
            levelsInfo={userInfo?.levels}
            isActiveLvl={!!userInfo?.levels?.includes(currentLevel)}
          />
          {/* <LevelsMapNew
            choosedLevel={currentLevel}
            setCurrentLevel={setCurrentLevel}
            lastActiveLevel={lastActiveLevel - 1}
            levelInfo={levelInfo}
            timerStep={timerLevelStep}
            swiperRef={swiperRef}
            getActiveSlideIndex={getActiveSlideIndex}
            levelsInfo={userInfo?.levels}
            isActiveLvl={!!userInfo?.levels?.includes(currentLevel)}
          /> */}
          <Button
            isAllowToActivate={
              config?.stand === "prod" ? currentLevel <= lastActiveLevel : true
            }
            transactionInfo={transactionInfoUpgrade}
            onCallTransaction={onCallTransactionUpgrade}
            level={currentLevel}
            lastActiveLvl={lastActiveLevel}
            isActiveLvl={!!userInfo?.levels?.includes(currentLevel)}
          />
        </div>
        <div className="hidden sm:flex absolute left-0 bottom-[130px] z-[11]">
          <Timer
            currentLevel={lastNextLevel}
            timerStep={timerStep}
            isCompleted={isCompleted}
            setIsCompleted={setIsCompleted}
            time={currentTimer}
          />
        </div>
        <Menu />
        <Transactions />
      </div>
    </div>
  );
};
