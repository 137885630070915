import { useState } from "react";
import { useGetContract } from "./useGetContract";
import { CONTRACT_NAMES } from "../constants";
import { toWei } from "../numbers";
import { increaseByPercent } from "../numbers";
import { DEFAULT_GAS_LIMIT } from "../constants";
import { callNotification } from "../notification";
import { parseErrorToUserReadableMessage } from "../format";

export const useRegistation = () => {
  const [isLoadingRegistration, setIsLoadingRegistration] = useState(false);
  const { getContract } = useGetContract();

  const registration = async (uplineId) => {
    if (!isLoadingRegistration) {
      setIsLoadingRegistration(true);
      try {
        const contractButton = await getContract(CONTRACT_NAMES.BUTTON);

        let gas = null;

        try {
          gas = await contractButton.estimateGas.registation(
            uplineId,
            "0x0000000000000000000000000000000000000000",
            {
              gasLimit: parseInt(gas)
                ? increaseByPercent(gas)
                : DEFAULT_GAS_LIMIT,
            },
          );
        } catch (e) {
          callNotification({
            type: "error",
            message: parseErrorToUserReadableMessage(e),
          });
        }

        const result = await contractButton.registation(
          uplineId,
          "0x0000000000000000000000000000000000000000",
          {
            gasLimit: parseInt(gas)
              ? increaseByPercent(gas)
              : DEFAULT_GAS_LIMIT,
          },
        );

        return result;
      } catch (e) {
        callNotification({
          type: "error",
          message: parseErrorToUserReadableMessage(e),
        });
      } finally {
        setIsLoadingRegistration(false);
      }
    }
  };

  return {
    registration,
    isLoadingRegistration,
  };
};
