import React from "react";
import { Item } from "./Item";

export const RewardList = ({ levelInfo }) => {
  return (
    <div className="flex flex-col w-full space-y-6">
      <div className="flex w-full justify-between">
        <span className="text-lg font-bold sm:text-base">Hacked blocks</span>
        <span className="text-lg font-bold sm:text-base">profit</span>
      </div>
      <div className="flex flex-col space-y-5">
        {levelInfo?.map((item, itemIndex) => {
          return <Item level={item} key={itemIndex} />;
        })}
      </div>
    </div>
  );
};
