import { useState } from "react";
import { setCookie, parseCookies } from "nookies";

export const useCheckReflink = () => {
  const [uplineData, setUplineData] = useState(null);

  const checkReflinkId = () => {
    const refData = new URL(window.location.toString()).searchParams.get(
      "invite",
    );
    const refIdData = new URL(window.location.toString()).searchParams.get(
      "inviteId",
    );
    if (refIdData) {
      setUplineData({ type: "refid", value: refIdData });
      setCookie(null, "buttonCash_upline_id", refIdData, {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      });
    } else {
      if (!refData) {
        const parseUpline = parseCookies()?.["buttonCash_upline_id"];
        if (!!parseUpline) {
          setUplineData({ type: "inviteId", value: parseUpline });
        }
      }
    }
  };

  return {
    uplineData,
    // checkReflink,
    checkReflinkId,
  };
};
