import { parseUnits, formatUnits } from "@ethersproject/units";

export const increaseByPercent = (number, percent = 70) => {
  const onePercent = number.div(100);

  return number.add(onePercent.mul(percent));
};

export function toWei(value) {
  return parseUnits(String(value), "ether");
}

export const bigIntToInt = (value) => {
  if (!!Number(value)) {
    return parseInt(value);
  }
};

export function fromWei(balance, unitName = "ether") {
  return formatUnits(String(balance), unitName);
}
