import React, { useState } from "react";
import Countdown from "react-countdown";
import { fromUnixTime } from "date-fns";

export const Timer = ({
  currentLevel,
  timerStep,
  isCompleted,
  setIsCompleted,
  time = null,
}) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      const finalHours =
        days > 0 ? days * 24 + hours : hours < 10 ? `0${hours}` : hours;
      const finalMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const finalSeconds = seconds < 10 ? `0${seconds}` : seconds;
      return (
        <div className="flex items-center justify-between w-[260px] bg-green rounded-xl py-[10px] px-4 sm:w-full sm:space-x-2 sm:h-[40px] sm:rounded-l-none ">
          <div className="flex flex-col space-y-1 sm:space-y-0">
            <span className="text-black text-xs sm:text-[8px] sm:leading-normal">
              Block {currentLevel + 1} left
            </span>
            <div className="flex space-x-3 font-bold text-black sm:text-[10px] sm:leading-normal">
              <span>{finalHours}h</span>
              <span>{finalMinutes}m</span>
              <span>{finalSeconds}s</span>
            </div>
          </div>
          <img className="sm:w-6 sm:h-6" src="/icons/timer/watchIcon.svg" />
        </div>
      );
    }
  };

  if (isCompleted || !time) {
    return null;
  }
  return (
    <div className={timerStep === 1 || timerStep === 2 ? "hidden" : ""}>
      <Countdown
        date={fromUnixTime(time)}
        renderer={renderer}
        onComplete={() => setIsCompleted(true)}
      />
    </div>
  );
};
