import { useState } from "react";
import { useGetContract } from "./useGetContract";
import { CONTRACT_NAMES } from "../constants";
import { bigIntToInt } from "../numbers";
import { fromWei } from "../numbers";
import { useNavigate } from "react-router-dom";

export const useGetUserInfo = () => {
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const { getContract } = useGetContract();
  const navigate = useNavigate();

  const getUserInfo = async (userAddress, isRedirected = false) => {
    if (!isLoadingUserInfo) {
      setIsLoadingUserInfo(true);
      try {
        const result = await getUserInfo1(userAddress);

        if ((!bigIntToInt(result?.id) && isRedirected) || !userAddress) {
          navigate("/");
          return;
        }

        if (result) {
          if (!!bigIntToInt(result?.id)) {
            setIsRegisteredUser(true);
          }
          try {
            const result2 = await getUserInfo2(userAddress);
            if (result2) {
              const user = {
                id: bigIntToInt(result?.id),
                referrer: result?.referrer,
                referrerId: bigIntToInt(result?.referrerId),
                tokens: bigIntToInt(result?.tokens),
                totalProfit: bigIntToInt(result?.totalProfit) || 0,
                partnersTotal: {
                  count: bigIntToInt(result?.totalPartnersCount) || 0,
                  profit: bigIntToInt(result?.totalPartnersProfit) || 0,
                },
                levels: result2?.levels,
              };

              setUserInfo(user);
            }
          } catch (e) {
            console.log(e);
          } finally {
            setIsLoadingUserInfo(false);
          }
        } else {
          setIsLoadingUserInfo(false);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingUserInfo(false);
      }
    }
  };

  const getUserInfo1 = async (userAddress) => {
    try {
      const contractButton = await getContract(CONTRACT_NAMES.BUTTON);
      const result = await contractButton.getUserData(userAddress);

      return result;
    } catch (e) {
      console.log(e);
    }
  };

  const getUserInfo2 = async (userAddress) => {
    try {
      const contractButton = await getContract(CONTRACT_NAMES.BUTTON);
      const result = await contractButton.getUserData2(userAddress);

      return result;
    } catch (e) {
      console.log(e);
    }
  };

  return {
    getUserInfo,
    userInfo,
    isRegisteredUser,
    isLoadingUserInfo,
  };
};
