import React from "react";
import { LevelReminder } from "../../../components/Modals/LevelReminderModal";
import { useModal } from "../../../helpers/hooks/useModal";
import { LevelInfoModal } from "../../../components/Modals/LevelInfoModal";

export const Item = ({
  levelInfo,
  index,
  price,
  active,
  disableInfoBtns = false,
}) => {
  const { openedModal, onClose, onOpen } = useModal();
  const {
    openedModal: openedInfoModal,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useModal();
  return (
    <div
      className={`flex flex-col relative items-center justify-between w-[180px] h-[151px] rounded-[20px] border-[1px] border-green sm:h-[89px] sm:w-[107px] sm:rounded-xl ${active ? "bg-lightGreen" : ""} `}
    >
      <span className="text-[80px] font-bold leading-none pt-2 sm:text-[48px] sm:pt-1.5 sm:leadinng-normal">
        {index}
      </span>
      <span className="absolute bottom-[40px] text-lg leading-none sm:text-[10px] sm:relative sm:bottom-auto">
        block
      </span>
      <div className="flex flex-col items-center justify-center sm:relative sm:w-full">
        <span className="text-black z-[11] text-lg sm:text-[10px]">
          {price} BNB
        </span>
        <img
          className="absolute sm:w-[81.1px] sm:h-[17.9px]"
          src="/icons/main/levelsMap/bigRectangle.svg"
        />
      </div>
      <button
        onClick={disableInfoBtns ? () => {} : () => onOpenInfoModal()}
        className="absolute top-4 right-4 z-[111] sm:right-2 sm:top-2"
      >
        <img
          className="w-6 h-6 sm:w-[14.3px] sm:h-[14.3px] z-[111]"
          src="/icons/main/leftBar/infoIcon.svg"
        />
      </button>
      {/* <button
        onClick={() => onOpen()}
        className="absolute top-4 left-4 sm:left-2 sm:top-2 z-[111]"
      >
        <img
          className="w-6 h-6 sm:w-[14.3px] sm:h-[14.3px]"
          src="/icons/main/levelsMap/reminder.svg"
        />
      </button> */}
      <LevelReminder openedModal={openedModal} onClose={onClose} />
      <LevelInfoModal
        level={{ num: index, price: price, info: levelInfo }}
        active={active}
        openedModal={openedInfoModal}
        onClose={onCloseInfoModal}
      />
    </div>
  );
};
