import { useMemo, useEffect } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import "./style.scss";
import "react-toastify/dist/ReactToastify.css";

import {
  metaMask,
  metaMaskHooks,
  walletConnectV2Hooks,
  walletConnectV2,
} from "./connectors/wallets";
import { RoutesFind } from "./RoutesFind";
import { ToastContainer } from "react-toastify";

function App() {
  const connectors = useMemo(() => {
    return [
      [metaMask, metaMaskHooks],
      [walletConnectV2, walletConnectV2Hooks],
    ];
  }, []);

  const isUserHandleDisconnect = localStorage.getItem("isUserHandleDisconnect");

  useEffect(() => {
    if (isUserHandleDisconnect) return;
    metaMask.connectEagerly().catch(() => {
      console.debug("Failed to connect eagerly to metamask");
    });
  }, []);

  useEffect(() => {
    if (isUserHandleDisconnect) return;
    walletConnectV2.connectEagerly().catch((error) => {
      console.debug("Failed to connect eagerly to walletconnect", error);
    });
  }, []);

  return (
    <Web3ReactProvider connectors={connectors}>
      <RoutesFind />
      <ToastContainer />
    </Web3ReactProvider>
  );
}

export default App;
