import { useState } from "react";
import { useGetContract } from "./useGetContract";
import { CONTRACT_NAMES } from "../constants";
import { bigIntToInt } from "../numbers";

export const useClaimRewards = () => {
  const [isLoadingGetRewards, setIsLoadingGetRewards] = useState(false);
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);
  const [rewards, setRewards] = useState(0);
  const { getContract } = useGetContract();

  const getRewardsForClaim = async (userAddress) => {
    if (!isLoadingGetRewards) {
      setIsLoadingGetRewards(true);
      try {
        const contractButton = await getContract(CONTRACT_NAMES.BUTTON);
        const result =
          await contractButton.getUserUnclaimedRewards(userAddress);
        const finalNum = bigIntToInt(result) || 0;
        setRewards(finalNum);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingGetRewards(false);
      }
    }
  };

  const claimRewards = async () => {
    if (!isLoadingClaim) {
      setIsLoadingClaim(true);
      try {
        const contractButton = await getContract(CONTRACT_NAMES.BUTTON);
        const result = await contractButton.claim();

        return result;
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingClaim(false);
      }
    }
  };

  return {
    rewards,
    claimRewards,
    getRewardsForClaim,
    isLoadingClaim,
    isLoadingGetRewards,
  };
};
