import React, { useEffect } from "react";
import { bigIntToInt } from "../../../helpers/numbers";
import { shortenAddress } from "../../../helpers/format";
import { useGetUserInfo } from "../../../helpers/hooks/useGetUserInfo";
import { useWeb3React } from "@web3-react/core";

export const Item = ({ info, num, partnersInfo }) => {
  const { account } = useWeb3React();
  const { getUserInfo, userInfo } = useGetUserInfo();

  const getUserData = async () => {
    try {
      await getUserInfo(partnersInfo[0][num]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account) {
      getUserData();
    }
  }, [account]);

  console.log(userInfo);

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex space-x-4 items-center">
        <span className="font-bold text-sm sm:font-medium">
          ID {bigIntToInt(info)}
          {/* ({shortenAddress(partnersInfo[0][num], 3)}) */}
        </span>
      </div>
      <span className="text-sm absolute left-1/2">
        {userInfo?.levels?.length || 0}
      </span>
      <span className="text-sm">
        {bigIntToInt(userInfo?.totalProfit) / 1e18 || 0} BNB
      </span>
    </div>
  );
};
