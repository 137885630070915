import config from "./config";

export const activationTimers =
  config?.stand === "dev"
    ? [
        {
          startTime: 1721969500,
          startTime2: 1721995500,
          endTime: 1721996100,
        },
        {
          startTime: 1721941200,
          startTime2: 1721999400,
          endTime: 1722013200,
        },
        {
          startTime: 1721969500,
          startTime2: 1721969500,
          endTime: 1721969500,
        },
      ]
    : [
        {
          startTime: 1721941200,
          startTime2: 1721988000,
          endTime: 1721998800,
        },
        {
          startTime: 1721941200,
          startTime2: 1721998801,
          endTime: 1722013200,
        },
        {
          startTime: 1721941200,
          startTime2: 1722070800,
          endTime: 1722085200,
        },
        {
          startTime: 1721941200,
          startTime2: 1722157200,
          endTime: 1722171600,
        },
        {
          startTime: 1721941200,
          startTime2: 1722243600,
          endTime: 1722268800,
        },
        {
          startTime: 1721941200,
          startTime2: 1722330000,
          endTime: 1722355200,
        },
        {
          startTime: 1721941200,
          startTime2: 1722416400,
          endTime: 1722524400,
        },
        {
          startTime: 1721941200,
          startTime2: 1722416400,
          endTime: 1722697200,
        },
        {
          startTime: 1721941200,
          startTime2: 1722416400,
          endTime: 1722869940,
        },
        {
          startTime: 1721941200,
          startTime2: 1722416400,
          endTime: 1723042800,
        },
        {
          startTime: 1721941200,
          startTime2: 1722416400,
          endTime: 1723215600,
        },
        {
          startTime: 1721941200,
          startTime2: 1722416400,
          endTime: 1723388400,
        },
      ];
