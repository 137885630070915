import React, { useEffect } from "react";
import { Item } from "./item";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { LEVEL_PRICE_TEXT } from "../../../helpers/constants";

export const LevelsMap = ({
  timerStep,
  swiperRef,
  getActiveSlideIndex,
  levelsInfo,
  isActiveLvl,
  levelInfo,
  lastActiveLevel,
}) => {
  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const slideTo = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  useEffect(() => {
    slideTo(lastActiveLevel);
  }, [lastActiveLevel]);

  return (
    <div className="flex items-center space-x-[6px] absolute top-[180px] sm:top-1/3 sm:w-full sm:px-4 sm:justify-center ">
      <button
        className="flex items-center w-[40px] sm:absolute sm:left-4 z-[11]"
        onClick={goToPrevSlide}
      >
        <img src="/icons/main/levelsMap/arrow.svg" />
      </button>
      <Swiper
        onSlideChange={getActiveSlideIndex}
        ref={swiperRef}
        className="mySwiper w-[500px] sm:w-[80%] relative"
        spaceBetween={30}
        slidesPerView={"auto"}
        centeredSlides={true}
      >
        {LEVEL_PRICE_TEXT.map((item, index) => (
          <SwiperSlide className={`swiper-slide-${index}`} key={item}>
            <Item
              levelInfo={levelInfo}
              timerStep={timerStep}
              active={!!levelsInfo && levelsInfo?.includes(index + 1)}
              price={timerStep <= 2 ? "S00N" : item}
              index={index + 1}
            />
          </SwiperSlide>
        ))}
        <div className="absolute top-0 w-[600px] w-full h-[400px]" />
      </Swiper>
      <button
        className="flex justify-end w-[40px] sm:absolute sm:right-4 z-[11] h-[32px]"
        onClick={goToNextSlide}
      >
        {/* <div className="h-[22px] w-[1px] bg-green rotate-[-33deg] z-[111]" />
        <div className="h-[22px] w-[1px] bg-green rotate-[33deg] mt-[18px] z-[111] " /> */}
        <img className="rotate-180" src="/icons/main/levelsMap/arrow.svg" />
      </button>
      {isActiveLvl && (
        <div className="flex items-center justify-center space-x-3 absolute top-[170px] left-1/2 -translate-x-1/2 w-[258px] bg-green py-[10px] sm:py-1 rounded-xl sm:top-[100px] sm:w-[140px]">
          <span className="text-black text-lg font-medium leading-normal sm:text-xs">
            Block hacked
          </span>
          <img
            className="sm:w-5 sm:h-5"
            src="/icons/main/levelActivated/approveIcon.svg"
          />
        </div>
      )}
    </div>
  );
};
