import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Main } from "./pages/main";
import { Login } from "./pages/login";
import { Earn } from "./pages/earn";
import { Frens } from "./pages/frens";
import { Btn } from "./pages/btn";
import { Loading } from "./pages/loading";
import { useWeb3React } from "@web3-react/core";
import config from "./helpers/config";

export const RoutesFind = () => {
  const { account, chainId, provider } = useWeb3React();
  const onSwitchChain = async () => {
    if (Number(chainId) !== Number(config.allowedChainId) && !!account) {
      const chainIntoHex =
        Number(config.allowedChainId) === 56 ? "0x38" : "0x61";
      try {
        await provider?.send("wallet_switchEthereumChain", [
          { chainId: chainIntoHex },
          account,
        ]);
      } catch (e) {
        if (e.code === 4902) {
          await provider?.send("wallet_addEthereumChain", [
            {
              chainId: chainIntoHex,
              ...addChainEthsParams[config.allowedChainId],
            },
          ]);
        }
      }
    }
  };

  useEffect(() => {
    onSwitchChain();
  }, [provider, chainId, account]);

  return (
    <Router>
      <Routes>
        <Route path="/dashboard" element={<Main />} />
        <Route path="/" element={<Login />} />
        <Route path="/earn" element={<Earn />} />
        <Route path="/frens" element={<Frens />} />
        <Route path="/btn" element={<Btn />} />
        <Route path="loading" element={<Loading />} />
      </Routes>
    </Router>
  );
};
