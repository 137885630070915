import React from "react";
import { bigIntToInt } from "../../../helpers/numbers";
import Spline from "@splinetool/react-spline";

export const RightBar = ({ totalProfit = 0, partnersTotal = {} }) => {
  return (
    <div className="relative flex flex-col items-end space-y-6 sm:flex-row-reverse sm:items-start sm:space-y-0 sm:w-content">
      <div className="flex flex-col space-y-3 items-end sm:text-xs w-[270px] sm:w-[200px] sm:space-y-1">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-[10px]">
            <img
              className="sm:w-[20px]"
              src="/icons/main/rightBar/lootIcon.svg"
            />
            <span>My loot:</span>
          </div>
          <span>[{(bigIntToInt(totalProfit) / 1e18 || 0).toFixed(4)}] BNB</span>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-[10px]">
            <img
              className="sm:w-[20px]"
              src="/icons/main/rightBar/profitIcon.svg"
            />
            <span>Gang loot:</span>
          </div>
          <span>
            [{(bigIntToInt(partnersTotal?.profit) / 1e18 || 0).toFixed(4)}] BNB
          </span>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-[10px]">
            <img
              className="sm:w-[20px]"
              src="/icons/main/rightBar/frensIcon.svg"
            />
            <span>My frens:</span>
          </div>
          <span>[{partnersTotal?.count || 0}] FRN</span>
        </div>
      </div>
      <Spline
        className="absolute right-[-100px] top-[110px] sm:!w-min sm:scale-[0.36] sm:ml-[-50px] sm:relative sm:top-[-60px] sm:right-0 sm:hidden lg:right-[-50px] "
        scene={"https://prod.spline.design/d34hSLhPqemUdYju/scene.splinecode"}
      />
    </div>
  );
};
