import { BigNumber } from "@ethersproject/bignumber";
import config from "./config";

export const CONTRACT_NAMES = {
  BUTTON: "button",
};

export const MAX_VALUE =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const DEFAULT_GAS_LIMIT = BigNumber.from(2000000);

export const SWAP_TOKEN_START = "2023-12-20T16:00Z";

export const FIRST_THIRTY_DATE = "2023-12-20T16:30Z";

export const BUY_LIST = [
  { title: "85", value: 85 },
  { title: "260", value: 260 },
  { title: "1050", value: 1050 },
  { title: "5250", value: 5250 },
  { title: "26250", value: 26250 },
  { title: "78450", value: 78450 },
  { title: "262500", value: 262500 },
];

export const LEVEL_PRICE =
  config.stand === "dev"
    ? [0.2, 0.13, 0.09011, 0.06606, 0.033, 0.015, 2, 1.3, 0.9, 0.66, 0.33, 0.15]
    : [
        0.2, 0.13, 0.09011, 0.06606, 0.033, 0.015, 2, 1.3, 0.9, 0.66, 0.33,
        0.15,
      ];

export const LEVEL_PRICE_TEXT =
  config.stand === "dev"
    ? [0.2, 0.13, 0.09, 0.066, 0.033, 0.015, 2, 1.3, 0.9, 0.66, 0.33, 0.15]
    : [0.2, 0.13, 0.09, 0.066, 0.033, 0.015, 2, 1.3, 0.9, 0.66, 0.33, 0.15];

export const SIGNATURE_CODES = {
  5: "0x000000000000000000000000000000000000000000000000000000000000001cfe60dfcbfc752488f31c2e2a2e6bc9cbc36b14379bbf9771f8ee17256f83b5f5138e77ba2f6da43c43fc0fc08d943baf6d89a28d25c78c0a81a9ae326afc9c3b",
};

export const NEED_LIST = [
  "0x90f2f9E443F87f612a2e126c8a090F7B40bDdF06",
  "0xBc58864d32c6E9b27BF1C3438d8928f1A8E7e653",
  "0x47ddFD27c6E010ca7e97A00933529B83dE2E55Ff",
  "0x15921A1B0eEd1dEb67B59dE46258a281c1333010",
  "0x30a54d91B9f1b864f03486FCD3bb86Af80aDA09d",
  "0xd009312e8d5f00972361d02bC9b1198B42dD8AfA",
];
