import React, { useEffect } from "react";
import { useBuyLevel } from "../../../helpers/hooks/useBuyLevel";
import { LEVEL_PRICE, NEED_LIST } from "../../../helpers/constants";
import { callNotification } from "../../../helpers/notification";
import { useWeb3React } from "@web3-react/core";

export const Button = ({
  level,
  lastActiveLvl,
  onCallTransaction,
  transactionInfo,
  isActiveLvl = false,
  isAllowToActivate = false,
}) => {
  const { account } = useWeb3React();
  const { upgradeLvl } = useBuyLevel();

  useEffect(() => {
    if (transactionInfo?.isSuccess) {
      callNotification({ type: "success", message: "Hacked" });
      // callNotification({ type: "default", message: "+ 1000 $BTN" });
    }
    if (!transactionInfo?.isSuccess && transactionInfo?.hash) {
      callNotification({ type: "info", message: "Hacking level" });
    }
  }, [transactionInfo]);

  const checkTheClick = () => {
    if (NEED_LIST.includes(account)) {
      clickToUpgrade();
    } else {
      setTimeout(() => {
        clickToUpgrade();
      }, 1000);
    }
  };

  const clickToUpgrade = async () => {
    if (isAllowToActivate) {
      if (!transactionInfo?.isWaiting) {
        try {
          const result = await upgradeLvl(level);

          onCallTransaction(result);
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      callNotification({
        type: "info",
        message: "Level will be available soon",
      });
    }
  };

  return (
    <div
      className={`${isAllowToActivate ? "" : "opacity-50"} flex flex-col sm:pb-[170px]`}
    >
      <div className="absolute bottom-[85px] left-1/2 -translate-x-1/2 z-[11] flex flex-col items-center space-y-4 sm:space-y-3 sm:bottom-[180px]">
        <button
          disabled={isActiveLvl}
          onClick={() => checkTheClick()}
          className={`flex flex-col mainButton ${transactionInfo?.isWaiting ? "animate-pulse" : ""} ${isActiveLvl || transactionInfo?.hash ? "pressed" : ""}`}
        >
          <div className="buttonEllipse absolute left-1/2 -translate-x-1/2 bottom-[100px] z-[111] bg-green w-[300px] h-[157px] xl:w-[220px] xl:h-[130px] xl:bottom-[70px] sm:!w-[175px] sm:!h-[98px] sm:!bottom-[55px]"></div>
          <div className="buttonBody z-[11] absolute left-1/2 -translate-x-1/2 bottom-[4px] h-[168px] w-[300px] rounded-b-[80%] xl:w-[220px] xl:h-[130px] sm:!w-[175px] sm:!h-[103px] sm:bottom-0 "></div>
        </button>
      </div>
      <div className="animation1 absolute bottom-0 left-1/2 -translate-x-1/2 z-[1] sm:!max-w-full sm:w-full" />
      <img
        className=" z-[1] absolute left-1/2 -translate-x-1/2 bottom-[-20px] w-[500px] sm:!w-[300px] sm:!bottom-[115px] xl:w-[370px] xl:bottom-[8px]"
        src="/images/main/button/substrate.png"
      />
      <img
        className=" z-[1] absolute left-1/2 -translate-x-1/2 bottom-[-20px] w-[500px] sm:w-[206px] sm:h-[140px] sm:bottom-[160px]"
        src="/images/main/button/shadow.png"
      />
    </div>
  );
};
