import { useState } from "react";
import { useGetContract } from "./useGetContract";
import { CONTRACT_NAMES } from "../constants";
import { bigIntToInt } from "../numbers";

export const useGetLevelInfo = () => {
  const [isLoadingLevel, setIsLoadingLevel] = useState(false);
  const [levelInfo, setLevelInfo] = useState({});
  const { getContract } = useGetContract();

  const getLevelInfo = async (userAddress, level) => {
    if (!isLoadingLevel) {
      setIsLoadingLevel(true);
      try {
        const contractButton = await getContract(CONTRACT_NAMES.BUTTON);
        const result = await contractButton.userLevels(userAddress, level);

        if (result) {
          setLevelInfo({
            missedPartners: bigIntToInt(result?.missedPartners) || 0,
            missedReward: bigIntToInt(result?.missedReward) / 1e18 || 0,
            partnersCount: bigIntToInt(result?.partnersCount) || 0,
            totalProfit: bigIntToInt(result?.totalProfit) / 1e18 || 0,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingLevel(false);
      }
    }
  };

  return {
    getLevelInfo,
    levelInfo,
  };
};
