import React, { useEffect } from "react";
import { EarnFrensLayout } from "../../layouts/EarnFrensLayout";
import { useModal } from "../../helpers/hooks/useModal";
import { InviteModal } from "../../components/Modals/InviteModal";
import { bigIntToInt } from "../../helpers/numbers";
import { useGetUserInfo } from "../../helpers/hooks/useGetUserInfo";
import { useWeb3React } from "@web3-react/core";
import { RewardList } from "../../features/earn/RewardList";
import { UserStatics } from "../../features/earn/UserStatics";
import { useNavigate } from "react-router-dom";

export const Earn = () => {
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const {
    openedModal: openedModalInvite,
    onClose: onCloseInvite,
    onOpen: onOpenInvite,
  } = useModal();

  const { getUserInfo, userInfo } = useGetUserInfo();

  useEffect(() => {
    if (account) {
      getUserInfo(account, true);
    } else {
      navigate("/");
    }
  }, [account]);

  return (
    <EarnFrensLayout>
      <div className="flex flex-col items-center justify-center relative space-y-6 w-full max-w-[420px] sm:justify-start sm:max-w-full sm:px-4 pt-[18px] sm:pt-[0] sm:pb-[115px]">
        <img className="iconsShadow" src="/icons/earn/earnIcon.svg" />
        <div className="flex flex-col space-y-3 items-center w-full">
          <span className="text-2xl font-bold">My reward</span>
        </div>
        <div className="flex flex-col items-center w-full max-w-[420px] space-y-3 sm:max-w-full">
          <UserStatics />
          <span className="sm:text-sm">-1000 BTN for claiming the reward</span>
          <span className="text-black font-medium">Invite new hackers</span>
          {/* <div className="bg-green h-[1px] opacity-10 w-full" /> */}
          <RewardList levelInfo={userInfo?.levels} />
        </div>
        <InviteModal
          userId={bigIntToInt(userInfo?.id)}
          openedModal={openedModalInvite}
          onClose={onCloseInvite}
        />
      </div>
    </EarnFrensLayout>
  );
};
