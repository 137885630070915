import React from "react";
import { Header } from "../../components/Header";
import { Menu } from "../../components/Menu";

export const EarnFrensLayout = ({ children }) => {
  return (
    <div className="flex flex-col items-center bg-black min-h-screen min-w-full overflow-y-hidden">
      <div className="relative flex flex-col space-y-[18px] items-center w-full px-[108px] max-w-[1500px] mt-5 sm:px-0 sm:justify-start">
        <Header
          className="sm:hidden !fixed !top-0  !px-[108px] !pt-[18px]"
          isEarnFrensLayout
        />
        {children}
      </div>
      <img
        className="fixed left-0 h-full z-[0] sm:hidden"
        src="/images/layouts/EarnFrensLayout/leftGrid.png"
      />
      <img
        className="fixed right-0 h-full z-[0] sm:hidden"
        src="/images/layouts/EarnFrensLayout/rightGrid.png"
      />
      <Menu />
    </div>
  );
};
