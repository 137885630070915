import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { ConnectWalletModal } from "../../../components/Modals/ConnectWalletModal";
import { useModal } from "../../../helpers/hooks/useModal";

export const ConnectSection = () => {
  const { openedModal, onClose, onOpen } = useModal();
  const buttonStyle =
    "flex items-center justify-center buttonMainStyle h-[48px] w-[350px] rounded-xl sm:w-fit sm:px-4 sm:h-[40px] sm:w-full text-black";

  return (
    <div className="flex flex-col items-center justify-center text-center space-y-6 bg-black h-screen w-screen sm:px-4">
      <img src="/icons/loginSection/walletIcon.svg" />
      <div className="flex flex-col items-center justify-center mb-5 space-y-3">
        <span className="text-[32px] uppercase font-semibold">
          Connect wallet <br />
          for loot
        </span>
        <div className="max-w-[350px] w-full font-light">
          Just a heads up, connect your wallet now to get hacking rewards.
        </div>
      </div>
      <button onClick={onOpen} className={buttonStyle}>
        <span className="text-black text-lg font-medium sm:text-sm">
          Connect wallet
        </span>
      </button>
      <ConnectWalletModal openedModal={openedModal} onClose={onClose} />
    </div>
  );
};
