import React, { useEffect, useState } from "react";
import { ConnectWalletModal } from "../Modals/ConnectWalletModal";
import { useModal } from "../../helpers/hooks/useModal";
import { useWeb3React } from "@web3-react/core";
import { shortenAddress } from "../../helpers/format";
import { useBalance } from "../../helpers/hooks/useBalance";
import { EarnFrensLayout } from "../../layouts/EarnFrensLayout";

export const Header = ({ isEarnFrensLayout, className }) => {
  const [isOpenedDisconnectMenu, setIsOpenedDisconnectMenu] = useState(false);
  const { account, connector } = useWeb3React();
  const { openedModal, onClose, onOpen } = useModal();
  const { fetchBalance, balanceBnb } = useBalance();

  const onDisconnectClick = () => {
    localStorage.setItem("isUserHandleDisconnect", true);
    connector.actions.resetState();
    window.location.replace("/");
  };

  useEffect(() => {
    if (account) {
      fetchBalance();
    }
  }, [account]);

  return (
    <div className={`flex justify-between w-full sm:px-4 z-[111] ${className}`}>
      <div className="flex items-center justify-start space-x-[17.25px] sm:space-x-2">
        <img className="h-[35px]" src="/icons/header/btnLogo.svg" />
        <span className="uppercase font-bold text-[32px] sm:text-sm sm:leading-5">
          Button <br className="hidden sm:flex" /> CA$H
        </span>
      </div>
      {account ? (
        <div className="relative">
          <div className="flex items-center space-x-3">
            <a
              className="sm:h-[25px] sm:w-[25px] shrink-0"
              target="_blank"
              href="https://t.me/buttoncashgame"
              rel="noreferrer"
            >
              <img src="/icons/telegram.svg" alt="" />
            </a>
            <div className="flex items-center justify-center h-[42px] px-3 border-[1px] border-green rounded-xl sm:hidden">
              <span>
                [{balanceBnb ? Number(balanceBnb).toFixed(2) : "-"} BNB]
              </span>
            </div>
            <div
              onClick={() => setIsOpenedDisconnectMenu((prev) => !prev)}
              className="flex items-center justify-center h-[42px] pl-3 pr-0.5 border-[1px] border-green rounded-xl space-x-4 sm:justify-between sm:pl-4 sm:w-max"
            >
              <span className="sm:text-sm">{shortenAddress(account)}</span>
              <button className="flex items-center justify-center buttonSecondStyle rounded-lg p-2 shrink-0">
                <img src="/icons/main/rightBar/walletIcon.svg" />
              </button>
            </div>
          </div>
          {isOpenedDisconnectMenu && (
            <div
              onClick={onDisconnectClick}
              className="z-[99999] absolute cursor-pointer right-0 top-14 buttonMainStyle font-medium rounded-[12px] py-[10px] px-[20px] text-black sm:text-sm"
            >
              Disconnect wallet
            </div>
          )}
        </div>
      ) : (
        <button
          onClick={() => onOpen()}
          className="flex items-center justify-center h-[42px] pl-3 pr-0.5 border-[1px] border-green rounded-xl space-x-4 sm:w-[184px]"
        >
          <span className="text-lg sm:text-sm sm:whitespace-nowrap">
            Connect Wallet
          </span>
          <div className="flex items-center justify-center buttonSecondStyle rounded-lg p-2">
            <img src="/icons/main/rightBar/walletIcon.svg" />
          </div>
        </button>
      )}

      {isOpenedDisconnectMenu && (
        <div
          onClick={() => setIsOpenedDisconnectMenu(false)}
          className="cursor-pointer z-[9999] absolute top-0 left-0 w-full h-full bg-darkgray-900"
        ></div>
      )}
      <ConnectWalletModal openedModal={openedModal} onClose={onClose} />
    </div>
  );
};
