import React from "react";
import { InviteModal } from "../../../components/Modals/InviteModal";
import { useModal } from "../../../helpers/hooks/useModal";
import { bigIntToInt } from "../../../helpers/numbers";
import { Link } from "react-router-dom";

export const Middle = ({ id = null }) => {
  const { openedModal, onClose, onOpen } = useModal();

  return (
    <div className="z-[3] flex flex-col space-y-3 items-center justify-start absolute left-1/2 -translate-x-1/2 top-6 sm:relative sm:top-0 sm:flex-row sm:space-y-0 sm:items-end sm:justify-between">
      {id ? (
        <>
          <span className="text-2xl font-bold sm:text-lg">
            ID {bigIntToInt(id)}
          </span>
          <button
            onClick={() => onOpen()}
            className="flex items-center justify-center buttonMainStyle h-[48px] w-[265px] rounded-xl sm:w-fit sm:px-4 sm:h-[40px]"
          >
            <span className="text-black text-lg font-medium sm:text-sm">
              Invite new hackers
            </span>
          </button>
        </>
      ) : (
        <>
          <span className="text-2xl font-bold sm:text-xl">ID Not Found</span>
          <Link
            to={"/"}
            className="flex items-center justify-center buttonMainStyle h-[48px] w-[265px] rounded-xl sm:w-fit sm:px-4 sm:h-[40px]"
          >
            <span className="text-black text-lg font-medium sm:text-sm">
              Sign up for free
            </span>
          </Link>
        </>
      )}
      <InviteModal
        userId={bigIntToInt(id)}
        openedModal={openedModal}
        onClose={onClose}
      />
    </div>
  );
};
