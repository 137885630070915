import { BaseRepository } from "./base";
import { requestApi } from "../api";

class Repository extends BaseRepository {
  getTransactions = (params) =>
    requestApi("get", `${this.path}/transactions`, params);
  getSignature = (level, params) =>
    requestApi("get", `${this.path}/${level}/signature`, params);
}

export const ActivitiesRepository = new Repository("/api");
