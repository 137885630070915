import { useState } from "react";
import { useGetContract } from "./useGetContract";
import { CONTRACT_NAMES } from "../constants";
import { toWei } from "../numbers";

export const useGetUserPartners = () => {
  const [isLoadingUserPartners, setIsLoadingUserPartners] = useState(false);
  const [partnersInfo, setUserPartnersInfo] = useState({});
  const { getContract } = useGetContract();

  const getUserPartners = async (userAddress, partnersTotal) => {
    if (!isLoadingUserPartners) {
      setIsLoadingUserPartners(true);
      try {
        const contractButton = await getContract(CONTRACT_NAMES.BUTTON);
        const result = await contractButton.getUserPartners(
          userAddress,
          0,
          partnersTotal - 1,
        );

        if (result) {
          setUserPartnersInfo(result);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoadingUserPartners(false);
      }
    }
  };

  return {
    getUserPartners,
    partnersInfo,
  };
};
