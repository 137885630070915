import React, { useEffect } from "react";
import { useGetLevelInfo } from "../../../helpers/hooks/useGetLevels";
import { useWeb3React } from "@web3-react/core";

export const Item = ({ level }) => {
  const { account } = useWeb3React();
  const { getLevelInfo, levelInfo } = useGetLevelInfo();

  useEffect(() => {
    if (account) {
      getLevelInfo(account, level);
    }
  }, [account]);

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center justify-start space-x-3">
        <div className="w-10 h-10 rounded-[8px] flex items-center justify-center bg-[#1AF012] text-[#0A0A0B] text-lg font-bold sm:text-base">
          {level}
        </div>
        <span>Block</span>
      </div>
      <span className="text-lg font-bold sm:text-base">
        [{levelInfo?.totalProfit}] BNB
      </span>
    </div>
  );
};
