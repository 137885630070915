import React, { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useGetUserPartners } from "../../../helpers/hooks/useGetPartners";
import { Item } from "./Item";

export const FrensList = ({ totalPartners }) => {
  const { account } = useWeb3React();
  const { partnersInfo, getUserPartners } = useGetUserPartners();

  useEffect(() => {
    if (account && totalPartners) {
      getUserPartners(account, totalPartners);
    }
  }, [account, totalPartners]);

  return (
    <div className="flex flex-col w-full space-y-3 relative">
      <div className="flex justify-between w-full">
        <span>Frens ID</span>
        <span>Hacked blocks</span>
        <span>Looted</span>
      </div>
      {!!partnersInfo &&
        partnersInfo[1]?.map((item, itemIndex) => {
          return (
            <div
              className="flex flex-col items-center jsutify-center space-y-3"
              key={itemIndex}
            >
              <Item info={item} num={itemIndex} partnersInfo={partnersInfo} />
              <div className="bg-green h-[1px] opacity-10 w-full" />
            </div>
          );
        })}
    </div>
  );
};
