import { isBefore, isEqual, parseISO, getUnixTime } from "date-fns";
import { format } from "date-fns";

export const parseUTC = (date) =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );

export const isSameOrBefore = (date) => {
  const mainDate = new Date(Date.now());

  if (date) {
    return (
      isBefore(date, getUnixTime(mainDate)) ||
      isEqual(date, getUnixTime(mainDate))
    );
  }
  return null;
};
