import React, { useEffect } from "react";
import { EarnFrensLayout } from "../../layouts/EarnFrensLayout";
import { useModal } from "../../helpers/hooks/useModal";
import { InviteModal } from "../../components/Modals/InviteModal";
import { useGetUserInfo } from "../../helpers/hooks/useGetUserInfo";
import { useWeb3React } from "@web3-react/core";
import { bigIntToInt } from "../../helpers/numbers";
import { useNavigate } from "react-router-dom";

export const Btn = () => {
  const navigate = useNavigate();
  const {
    openedModal: openedModalInvite,
    onClose: onCloseInvite,
    onOpen: onOpenInvite,
  } = useModal();
  const { account } = useWeb3React();
  const { getUserInfo, userInfo } = useGetUserInfo();

  useEffect(() => {
    if (account) {
      getUserInfo(account, true);
    } else {
      navigate("/");
    }
  }, [account]);

  return (
    <EarnFrensLayout>
      <div className="flex flex-col items-center justify-center relative space-y-6 w-full max-w-[420px] sm:justify-start sm:max-w-full sm:px-4 pt-[18px] sm:pb-[115px] sm:pt-[0]">
        <img
          className="w-[60px] h-[60px]"
          src="/icons/main/leftBar/tokenIcon.svg"
        />
        <span className="text-2xl font-bold">$BTN</span>
        <span className="text-center sm:text-sm">
          Score BTN by bringing in frens and use it to claim your BNB rewards
        </span>
        <div className="flex flex-col space-y-3 w-full">
          <div className="flex items-center justify-between w-full border-[1px] border-green rounded-xl p-4">
            <span className="text-xl sm:text-base ">Balance</span>
            <div className="flex items-center space-x-3">
              <span className="text-xl leading-normal sm:text-base">
                {userInfo?.tokens || 0}
              </span>
              <img
                className="w-6 h-6"
                src="/icons/main/leftBar/tokenIcon.svg"
              />
            </div>
          </div>
          <button
            onClick={() => onOpenInvite()}
            className="flex items-center justify-center buttonMainStyle rounded-xl bg-green h-[48px] w-full"
          >
            <span className="text-black font-medium">Invite new hackers</span>
          </button>
        </div>
        <span className="text-center sm:text-sm">
          +1000 $BTN for each of them
          <br /> +100 $BTN every time they activate block
        </span>
        <div className="w-full h-[1px] bg-green" />
        <img src="/icons/btn/lockIcon.svg" />
        <span className="text-center sm:text-sm">
          $BTN is about to blow up! It’s on fire right now and the future looks
          ridiculously bright. Smart move to start scooping some up now, before
          everyone else catches on! You`ll be thanking yourself later
        </span>
      </div>
      <InviteModal
        userId={bigIntToInt(userInfo?.id)}
        openedModal={openedModalInvite}
        onClose={onCloseInvite}
      />
    </EarnFrensLayout>
  );
};
